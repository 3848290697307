import formatSalary from '@vangst/lib/formatSalary'
import { JobPostingComputed } from '@vangst/services/oogst/jobPosting/useJobPosting'
import { AtsRecordTypeEnum } from '@vangst/services/oogst/types'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import { memo } from 'react'
import Markdown from '../../../components/views/Markdown'
import ActivitiesRenderer from '../../application/ActivitiesRenderer'
import { serializeJsonToReact } from '../../editor/serializers'
import GigsInfo from './GigsInfo'
import JobDetailBlock from './JobDetailBlock'

type PropsType = React.HTMLAttributes<HTMLDivElement> & {
  readonly jobPosting?: JobPostingComputed
}

function JobPostingDetail(props: PropsType) {
  const { jobPosting, ...rest } = props
  const { viewer } = useViewer()
  const na = 'No information provided.'

  // This attempts to format the description string of synced pikl jobs into something that looks decent
  const formatTextTwo = (text: string) => {
    // First unescape the string and normalize line breaks
    const normalizedText = text
      .replace(/\\r\\n/g, '\n') // Handle escaped \r\n
      .replace(/\\n/g, '\n') // Handle escaped \n
      .replace(/\r\n/g, '\n') // Handle actual \r\n
      .replace(/["]/g, '') // Remove quotes if present

    return normalizedText.split('\n').map((line, index) => {
      const trimmedLine = line.trim()

      // Skip empty lines
      if (!trimmedLine) {
        return <div key={index} className="h-4"></div>
      }

      // Check if line starts with bullet point indicators
      if (
        trimmedLine.startsWith('-') ||
        trimmedLine.startsWith('•') ||
        /^\s*•/.test(trimmedLine)
      ) {
        return (
          <div key={index} className="flex items-start py-1 pl-4">
            <span className="mr-2">•</span>
            <span>{trimmedLine.replace(/^[-•]\s*/, '')}</span>
          </div>
        )
      }
      // Check if line is a section header (ends with :)
      else if (trimmedLine.endsWith(':')) {
        return (
          <h2 key={index} className="mb-3 mt-6 text-lg font-700">
            {trimmedLine}
          </h2>
        )
      }
      // Regular text line
      else {
        return (
          <div key={index} className="py-1">
            {trimmedLine}
          </div>
        )
      }
    })
  }

  return (
    <div {...rest}>
      <div className="flow-y-sm">
        {viewer?.permissions?.internalUser &&
          jobPosting?.atsRecordType != AtsRecordTypeEnum.PlatformFree && (
            <>
              <GigsInfo jobPosting={jobPosting} />
              <div>
                <h2>Activities:</h2>
                <ActivitiesRenderer
                  className="mt-4 border border-grey-light p-2 shadow-inner"
                  classNameList="flow-y-xs overflow-y-auto max-h-96"
                  endpoint="getJobPostingActivities"
                  variables={{ id: jobPosting?.id ?? '' }}
                  relayId={jobPosting?.id}
                />
              </div>
            </>
          )}

        <section>
          <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
            Company & Role Overview
          </h2>
          <div className="flow-y-sm mood-grey-lightest p-4">
            <JobDetailBlock title="Summary">
              {jobPosting && jobPosting.description
                ? jobPosting.externalId
                  ? formatTextTwo(jobPosting.description)
                  : serializeJsonToReact(jobPosting.description)
                : 'Description Unavailable'}
            </JobDetailBlock>

            {jobPosting?.salaryRange &&
              (jobPosting.salaryRange.minValue ||
                jobPosting.salaryRange.maxValue) && (
                <JobDetailBlock title="Salary">
                  {formatSalary(jobPosting.salaryRange)}
                </JobDetailBlock>
              )}

            <JobDetailBlock
              title="Company Benefits"
              subtext="Benefits for this job may vary."
            >
              {jobPosting?.formattedBenefits ? (
                <span>{jobPosting?.formattedBenefits}</span>
              ) : null}
            </JobDetailBlock>

            <JobDetailBlock title="Dates">
              {jobPosting?.startDate != null ? (
                <>
                  {jobPosting?.startDateWords} -{' '}
                  {jobPosting?.endDateWords != null
                    ? jobPosting?.endDateWords
                    : 'No End Date'}
                </>
              ) : null}
            </JobDetailBlock>

            <JobDetailBlock title="Working Environment">
              {jobPosting?.workingEnvironment &&
                serializeJsonToReact(jobPosting.workingEnvironment)}
            </JobDetailBlock>

            <JobDetailBlock title={`About ${jobPosting?.client?.name}`}>
              {jobPosting?.client?.description && (
                <Markdown>{jobPosting?.client?.description}</Markdown>
              )}
            </JobDetailBlock>
          </div>
        </section>

        {!jobPosting?.isIngested && (
          <section>
            <h2 className="mood-orange flex h-16 items-center pl-6 font-600">
              Role Requirements
            </h2>

            <div className="flow-y-sm mood-grey-lightest p-4">
              <JobDetailBlock title="Responsibilities">
                {jobPosting?.responsibilities
                  ? serializeJsonToReact(jobPosting.responsibilities)
                  : na}
              </JobDetailBlock>
              <JobDetailBlock title="Physical Requirements">
                {jobPosting?.physicalRequirements
                  ? serializeJsonToReact(jobPosting.physicalRequirements)
                  : na}
              </JobDetailBlock>
              <JobDetailBlock title="Experience">
                {jobPosting?.experience
                  ? serializeJsonToReact(jobPosting.experience)
                  : na}
              </JobDetailBlock>
              <JobDetailBlock title="Education">
                {jobPosting?.education
                  ? serializeJsonToReact(jobPosting.education)
                  : na}
              </JobDetailBlock>
            </div>
          </section>
        )}
      </div>
    </div>
  )
}

export default memo(JobPostingDetail)
