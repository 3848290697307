import clsx from '@vangst/lib/clsx'
import { HUBSPOT_SUBJECTS } from '@vangst/services/hubspot'
import useViewer from '@vangst/services/oogst/viewer/useViewer'
import R from '@vangst/services/routes'
import { useRouter } from 'next/router'
import { memo, useEffect, useMemo, useState } from 'react'
import { MdError, MdUnpublished } from 'react-icons/md'
import AlertText from '../../components/feedback/AlertText'
import ClickyLink from '../../components/navigation/ClickyLink'
import DialogContact from '../job/feedback/DialogContact'
import DialogIndispensableSurvey from '../member/feedback/DialogIndispensableSurvey'
import AlertDialogSignInOrUp from '../viewer/feedback/DialogSignInOrUp'

function Beacons() {
  const { error, viewer } = useViewer()
  const { asPath, pathname } = useRouter()

  const [showContactForm, setShowContactForm] = useState(false)
  const [showLoginDialog, setShowLoginDialog] = useState<boolean | null>(false)

  if (error != null) {
    // Rethrow these high priority errors for the Boundary to pick up
    if (error.name === 'TERMS_NOT_ACCEPTED' && pathname !== R.LEGAL_TERMS)
      throw error
  }

  // :grimacing:
  const isIndispensableSurveyRoute = useMemo(
    () => pathname === R.DASHBOARD || asPath.includes('jobs'),
    [pathname, asPath],
  )

  const dontShowModalRoute = useMemo(() => {
    const dontShowSignInPromptRoutes = [
      R.ADMIN_IMPOSTOR,
      R.MEMBER_FORGOT_PASSWORD,
      R.MEMBER_CLAIM_ACCOUNT,
      R.MEMBER_RESET_PASSWORD,
      R.SIGN_IN,
      R.SIGN_UP,
      R.SIGN_UP_COMPANY,
    ]

    return dontShowSignInPromptRoutes.includes(pathname as R)
  }, [pathname])

  useEffect(() => {
    // This determines whether or not to fire the "sign up" dialog
    setTimeout(() => {
      const hasBeenDismissed =
        globalThis?.sessionStorage?.getItem('showLoginDialog') === 'false'

      if (viewer != null) {
        return
      }

      if (dontShowModalRoute) {
        return
      }

      if (hasBeenDismissed) {
        return
      }

      setShowLoginDialog(true)
    }, 1000)
  }, [pathname, dontShowModalRoute, viewer])

  const showIncompleteClientProfile =
    viewer?.client?.profileComplete === false && viewer?.client?.routes

  const showSurveyPrompt =
    viewer?.permissions?.triggerIndispensableSurvey &&
    isIndispensableSurveyRoute

  const showUnverifiedBanner = viewer?.client?.permissions?.readPendingBanner

  const handleCloseSignupDialog = () => {
    setShowLoginDialog(false)
    globalThis?.sessionStorage?.setItem('showLoginDialog', 'false')
  }

  // -------------------------------------

  return (
    <>
      {viewer?.isMember && viewer?.routes && (
        <>{showSurveyPrompt && <DialogIndispensableSurvey />}</>
      )}

      {!viewer && showLoginDialog && (
        <AlertDialogSignInOrUp handleClose={handleCloseSignupDialog} />
      )}

      {showIncompleteClientProfile && (
        <>
          <div className="z-10">
            <AlertText
              className="contain-xc mood-red min-h-12 text-sm"
              icon={MdError}
            >
              <span>
                Your user profile is incomplete,{' '}
                <ClickyLink
                  className="underline"
                  href={viewer?.client?.routes?.edit ?? ''}
                >
                  add additional information
                </ClickyLink>{' '}
                .
              </span>
            </AlertText>
          </div>
          {showSurveyPrompt && <DialogIndispensableSurvey />}
        </>
      )}

      {showUnverifiedBanner && (
        <>
          <div className="z-10">
            <AlertText
              className={clsx(
                showIncompleteClientProfile ? 'mood-blue' : 'mood-red',
                'contain-xc min-h-12 text-sm',
              )}
              icon={MdUnpublished}
            >
              <span>
                Your company profile is unverified.{' '}
                <ClickyLink
                  onClick={() => setShowContactForm(true)}
                  className="underline"
                >
                  Contact us to get verified
                </ClickyLink>{' '}
                .
              </span>
            </AlertText>
            {showContactForm && (
              <DialogContact
                email={viewer?.email ?? ''}
                phone={viewer?.phoneNumber ?? ''}
                state={viewer?.location?.state ?? ''}
                firstName={viewer?.firstName ?? ''}
                lastName={viewer?.lastName ?? ''}
                handleCancel={() => setShowContactForm(false)}
                hideDescription={true}
                title="Get Verified"
                hubspotContactSubject={HUBSPOT_SUBJECTS.COMPANY_SUPPORT}
              />
            )}
          </div>
        </>
      )}
    </>
  )
}

// -------------------------------------

export default memo(Beacons)
